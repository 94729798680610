import { ReactComponent as Github } from '../data/Github.svg'
import { ReactComponent as Close } from '../data/Close.svg'
import Modal from 'react-modal'

Modal.setAppElement('#root')

export const InfoModal = ({ isOpen, handleClose, darkMode, styles }) => (
  <Modal isOpen={isOpen} onRequestClose={handleClose} style={styles} contentLabel="Game Info Modal">
    <div className={`h-full ${darkMode ? 'dark' : ''}`}>
      <button
        className="absolute top-4 right-4 rounded-full nm-flat-background dark:nm-flat-background-dark text-primary dark:text-primary-dark p-1 w-6 h-6 sm:p-2 sm:h-8 sm:w-8 hover:nm-inset-background dark:hover:nm-inset-background-dark"
        onClick={handleClose}
      >
        <Close />
      </button>
      <div className="h-full flex flex-col items-center justify-center max-w-[390px] mx-auto pt-9 text-primary dark:text-primary-dark">
        <div className="flex-1 w-full sm:text-base text-sm">
          <h1 className="text-center sm:text-3xl text-2xl">Како се игра Вордли?</h1>
          <ul className="list-disc pl-5 block sm:text-base text-sm">

            <li className="mt-6 mb-2">Погађа се реч од 5 слова</li>
            <li className="mb-2">Сваког дана се погађа нова реч. </li>
            <li className="mb-2">Реч треба погодити из што мање покушаја (највише 6). </li>
            <li className="mb-2">Уносе се само постојеће српске речи.</li>
            <li className="mb-2">После сваког покушаја слова се означавају зеленом, жутом или сивом бојом.</li>
          </ul>

          <div className="mb-5 mt-5 flex items-center">
            <span>Примери:</span>
          </div>

          <div className="mb-1 mt-2 flex items-center justify-center">
            <span className="nm-inset-n-green text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              Љ
            </span>
            <span className="nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              У
            </span>
            <span className="nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              Б
            </span>
            <span className="nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              А
            </span>
            <span className="nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              В
            </span>
          </div>
          <div className="mb-3 mt-1 flex items-center justify-center">
            <span>Слово постоји и на правом је месту</span>
          </div>




          <div className="mb-1 flex items-center justify-center">
            <span className="nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              Љ
            </span>
            <span className="nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              У
            </span>
            <span className="nm-inset-yellow-500 text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              Б
            </span>
            <span className="nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              А
            </span>
            <span className="nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              В
            </span>
          </div>

          <div className="mb-3 mt-1 flex items-center justify-center">
            <span>Слово постоји, али није на правом месту</span>
          </div>


          <div className="mb-1 flex justify-center">
            <span className="nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              Љ
            </span>
            <span className="nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              У
            </span>
            <span className="nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              Б
            </span>
            <span className="nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              А
            </span>
            <span className="nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full mr-2">
              В
            </span>
          </div>

          <div className="mb-3 mt-1 flex items-center justify-center">
            <span>Слово не постоји у речи</span>
          </div>

        </div>
        <br/>
      Ако уживате у игри, скините и верзију за мобилни
        <div className="flex justify-center sm:text-base text-sm">
        <a href='https://play.google.com/store/apps/details?id=pm.f2p.vordli&utm_source=site&utm_campaign=info&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className ='googleplay' alt='Набавите на Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/sr_badge_web_generic.png'/></a>
        <a href='https://apps.apple.com/us/app/vordli/id1613087917'>
        <img class ='googleplay' alt='Набавите на App Store' src='appstore_badge.png'/>
        </a>
        </div>
      </div>
    </div>
  </Modal>
)
