import { ReactComponent as Close } from '../data/Close.svg'
import Modal from 'react-modal'
import Success from '../data/Success.png'
import Fail from '../data/Cross.png'

Modal.setAppElement('#root')

export const EndGameModal = ({
  isOpen,
  handleClose,
  styles,
  darkMode,
  gameState,
  state,
  currentStreak,
  longestStreak,
  answer,
  playAgain,
  shareResult,
}) => {
  const PlayAgainButton = () => {
    return (
      <div className={darkMode ? 'dark' : ''}>
        <button
          type="button"
          className="rounded-lg px-6 py-2 mt-8 text-lg nm-flat-background dark:nm-flat-background-dark hover:nm-inset-background dark:hover:nm-inset-background-dark text-primary dark:text-primary-dark"
          onClick={playAgain}
        >
          Покушај поново
        </button>
      </div>
    )
  }

  const ShareResultButton = () => {
    return (
      <div className={darkMode ? 'dark' : ''}>
        <button
          type="button"
          className="rounded-lg px-6 py-2 mt-8 text-lg nm-flat-background dark:nm-flat-background-dark hover:nm-inset-background dark:hover:nm-inset-background-dark text-primary dark:text-primary-dark"
          onClick={shareResult}
        >
          Подели резултате
        </button>
      </div>
    )
  }




  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={styles}
      contentLabel="Game End Modal"
    >
      <div className={darkMode ? 'dark' : ''}>
        <div className="h-full flex flex-col items-center justify-center max-w-[300px] mx-auto text-primary dark:text-primary-dark">
            <button
              className="absolute top-4 right-4 rounded-full nm-flat-background dark:nm-flat-background-dark text-primary dark:text-primary-dark p-1 w-6 h-6 sm:p-2 sm:h-8 sm:w-8 hover:nm-inset-background dark:hover:nm-inset-background-dark"
              onClick={handleClose}
            >
              <Close />
            </button>
          {gameState === state.won && (
            <>
              <img src={Success} alt="success" height="auto" width="auto" />
              <h1 className=" text-3xl">Честитамо!</h1>
              {/*}
              <p className="mt-6">
                Current streak: <strong>{currentStreak}</strong> {currentStreak > 4 && '🔥'}
              </p>
              <p>
                Longest streak: <strong>{longestStreak}</strong>
              </p>
              */}
            </>
          )}
          {gameState === state.lost && (
            <>
              <img src={Fail} alt="success" height="auto" width="80%" />
              <div className="text-primary dark:text-primary-dark text-4xl text-center">
                <p>УПС!</p>
                <p className="mt-3 text-2xl">
                  Нисте погодили реч.
                  {/*
                  Тражена реч је <strong>{answer}</strong>
                  */}
                </p>
                {/*
                <p className="mt-6 text-base">
                  Current streak: <strong>{currentStreak}</strong> {currentStreak > 4 && '🔥'}
                </p>
                <p className="text-base">
                  Longest streak: <strong>{longestStreak}</strong>
                </p>
                */}
              </div>
            </>
          )}

          {/*
          <PlayAgainButton />
          */}

          <ShareResultButton />

          <div className="flex flex-col items-center">
          <br/>
          <br/>
          </div>

          <div className="flex justify-center sm:text-base text-sm">
          <div className="flex flex-col items-center">
            Ако уживате у игри, скините и верзију за мобилни

          </div>
          </div>

          <a href='https://apps.apple.com/us/app/vordli/id1613087917'>
          <img class ='googleplay' alt='Набавите на App Store' src='appstore_badge.png'/>
          </a>

          <a href='https://play.google.com/store/apps/details?id=pm.f2p.vordli&utm_source=site&utm_campaign=endgame&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
          <img class ='googleplay' alt='Набавите на Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/sr_badge_web_generic.png'/>
          </a>


        </div>
      </div>
    </Modal>
  )
}
