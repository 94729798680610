import { letters, status, letters_eng_srb } from './constants'
import { useEffect, useState } from 'react'

import { EndGameModal } from './components/EndGameModal'
import { ReactComponent as Info } from './data/Info.svg'
import { InfoModal } from './components/InfoModal'
import { Keyboard } from './components/Keyboard'
import { ReactComponent as Settings } from './data/Settings.svg'
import { SettingsModal } from './components/SettingsModal'
import Confetti from 'react-dom-confetti';
import answers from './data/answers'
import { useLocalStorage } from './hooks/useLocalStorage'
import words from './data/words'
import logo from './data/logo.png'
import { isMobile } from 'react-device-detect';

const state = {
  playing: 'playing',
  won: 'won',
  lost: 'lost',
}

const shareState = {
  notclicked: 'notclicked',
  clicked: 'clicked',
}

const confettiConfig = {
  angle: 90,
  spread: 180,
  startVelocity: 60,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 2500,
  stagger: 3,
  width: "20px",
  height: "20px",
  perspective: "500px",
  colors: ["#63AA55", "#EAB308"]
};



const getRandomAnswer = () => {

  const date = getTodaysDate();

  var text = '';

  if (date == '29/8/2023') {
    text = 'прија';
  } else if (date == '30/8/2023') {
    text = 'изрод';
  } else if (date == '31/8/2023') {
    text = 'крива';
  } else if (date == '1/9/2023') {
    text = 'шнала';
  } else if (date == '2/9/2023') {
    text = 'фиока';
  } else if (date == '3/9/2023') {
    text = 'пацов';
  } else if (date == '4/9/2023') {
    text = 'мржња';
  } else if (date == '5/9/2023') {
    text = 'узрок';
  } else if (date == '6/9/2023') {
    text = 'обиље';
  } else if (date == '7/9/2023') {
    text = 'облик';
  } else if (date == '8/9/2023') {
    text = 'самац';
  } else if (date == '9/9/2023') {
    text = 'терме';
  } else if (date == '10/9/2023') {
    text = 'темељ';
  } else if (date == '11/9/2023') {
    text = 'устав';
  } else if (date == '12/9/2023') {
    text = 'амбар';
  } else if (date == '13/9/2023') {
    text = 'завет';
  } else if (date == '14/9/2023') {
    text = 'штанд';
  } else if (date == '15/9/2023') {
    text = 'педер';
  } else if (date == '16/9/2023') {
    text = 'лакат';
  } else if (date == '17/9/2023') {
    text = 'коала';
  } else if (date == '18/9/2023') {
    text = 'кутак';
  } else if (date == '19/9/2023') {
    text = 'корзо';
  } else if (date == '20/9/2023') {
    text = 'казна';
  } else if (date == '21/9/2023') {
    text = 'мазут';
  } else if (date == '22/9/2023') {
    text = 'флаша';
  } else if (date == '23/9/2023') {
    text = 'јатак';
  } else if (date == '24/9/2023') {
    text = 'шетач';
  } else if (date == '25/9/2023') {
    text = 'листа';
  } else if (date == '26/9/2023') {
    text = 'оглед';
  } else if (date == '27/9/2023') {
    text = 'низак';
  } else if (date == '28/9/2023') {
    text = 'поноћ';
  } else if (date == '29/9/2023') {
    text = 'ларма';
  } else if (date == '30/9/2023') {
    text = 'обућа';
  } else if (date == '1/10/2023') {
    text = 'рампа';
  } else if (date == '2/10/2023') {
    text = 'завој';
  } else if (date == '3/10/2023') {
    text = 'хитац';
  } else if (date == '4/10/2023') {
    text = 'израз';
  } else if (date == '5/10/2023') {
    text = 'модем';
  } else if (date == '6/10/2023') {
    text = 'мрежа';
  } else if (date == '7/10/2023') {
    text = 'запах';
  } else if (date == '8/10/2023') {
    text = 'лимар';
  } else if (date == '9/10/2023') {
    text = 'корпа';
  } else if (date == '10/10/2023') {
    text = 'отров';
  } else if (date == '11/10/2023') {
    text = 'такса';
  } else if (date == '12/10/2023') {
    text = 'колаж';
  } else if (date == '13/10/2023') {
    text = 'играч';
  } else if (date == '14/10/2023') {
    text = 'лавор';
  } else if (date == '15/10/2023') {
    text = 'пудла';
  } else if (date == '16/10/2023') {
    text = 'засад';
  } else if (date == '17/10/2023') {
    text = 'редар';
  } else if (date == '18/10/2023') {
    text = 'метал';
  } else if (date == '19/10/2023') {
    text = 'метак';
  } else if (date == '20/10/2023') {
    text = 'икона';
  } else if (date == '21/10/2023') {
    text = 'октет';
  } else if (date == '22/10/2023') {
    text = 'шарка';
  } else if (date == '23/10/2023') {
    text = 'нафта';
  } else if (date == '24/10/2023') {
    text = 'песма';
  } else if (date == '25/10/2023') {
    text = 'сукоб';
  } else if (date == '26/10/2023') {
    text = 'млеко';
  } else if (date == '27/10/2023') {
    text = 'момак';
  } else if (date == '28/10/2023') {
    text = 'авион';
  } else if (date == '29/10/2023') {
    text = 'најам';
  } else if (date == '30/10/2023') {
    text = 'пекар';
  } else if (date == '31/10/2023') {
    text = 'тацна';
  } else if (date == '1/11/2023') {
    text = 'квант';
  } else if (date == '2/11/2023') {
    text = 'замор';
  } else if (date == '3/11/2023') {
    text = 'купац';
  } else if (date == '4/11/2023') {
    text = 'повод';
  } else if (date == '5/11/2023') {
    text = 'ресор';
  } else if (date == '6/11/2023') {
    text = 'сафир';
  } else if (date == '7/11/2023') {
    text = 'мемла';
  } else if (date == '8/11/2023') {
    text = 'облак';
  } else if (date == '9/11/2023') {
    text = 'лонче';
  } else if (date == '10/11/2023') {
    text = 'текст';
  } else if (date == '11/11/2023') {
    text = 'фазон';
  } else if (date == '12/11/2023') {
    text = 'узица';
  } else if (date == '13/11/2023') {
    text = 'шљака';
  } else if (date == '14/11/2023') {
    text = 'лимун';
  } else if (date == '15/11/2023') {
    text = 'рецка';
  } else if (date == '16/11/2023') {
    text = 'екцем';
  } else if (date == '17/11/2023') {
    text = 'порок';
  } else if (date == '18/11/2023') {
    text = 'сунце';
  } else if (date == '19/11/2023') {
    text = 'ваљак';
  } else if (date == '20/11/2023') {
    text = 'пуњач';
  } else if (date == '21/11/2023') {
    text = 'мућак';
  } else if (date == '22/11/2023') {
    text = 'лосос';
  } else if (date == '23/11/2023') {
    text = 'тикет';
  } else if (date == '24/11/2023') {
    text = 'фраза';
  } else if (date == '25/11/2023') {
    text = 'успон';
  } else if (date == '26/11/2023') {
    text = 'самба';
  } else if (date == '27/11/2023') {
    text = 'одаја';
  } else if (date == '28/11/2023') {
    text = 'здрав';
  } else if (date == '29/11/2023') {
    text = 'песак';
  } else if (date == '30/11/2023') {
    text = 'оцена';
  } else if (date == '1/12/2023') {
    text = 'анекс';
  } else if (date == '2/12/2023') {
    text = 'арија';
  } else if (date == '3/12/2023') {
    text = 'харфа';
  } else if (date == '4/12/2023') {
    text = 'стаза';
  } else if (date == '5/12/2023') {
    text = 'кулен';
  } else if (date == '6/12/2023') {
    text = 'менза';
  } else if (date == '7/12/2023') {
    text = 'кефир';
  } else if (date == '8/12/2023') {
    text = 'лопта';
  } else if (date == '9/12/2023') {
    text = 'оквир';
  } else if (date == '10/12/2023') {
    text = 'округ';
  } else if (date == '11/12/2023') {
    text = 'замах';
  } else if (date == '12/12/2023') {
    text = 'нинџа';
  } else if (date == '13/12/2023') {
    text = 'пушка';
  } else if (date == '14/12/2023') {
    text = 'трафо';
  } else if (date == '15/12/2023') {
    text = 'торба';
  } else if (date == '16/12/2023') {
    text = 'скела';
  } else if (date == '17/12/2023') {
    text = 'свађа';
  } else if (date == '18/12/2023') {
    text = 'прота';
  } else if (date == '19/12/2023') {
    text = 'патка';
  } else if (date == '20/12/2023') {
    text = 'акреп';
  } else if (date == '21/12/2023') {
    text = 'јахта';
  } else if (date == '22/12/2023') {
    text = 'омлет';
  } else if (date == '23/12/2023') {
    text = 'шурак';
  } else if (date == '24/12/2023') {
    text = 'петља';
  } else if (date == '25/12/2023') {
    text = 'темпо';
  } else if (date == '26/12/2023') {
    text = 'чизма';
  } else if (date == '27/12/2023') {
    text = 'петак';
  } else if (date == '28/12/2023') {
    text = 'кокош';
  } else if (date == '29/12/2023') {
    text = 'однос';
  } else if (date == '30/12/2023') {
    text = 'ролат';
  } else if (date == '31/12/2023') {
    text = 'лупеж';
  } else if (date == '1/1/2024') {
    text = 'сфера';
  } else if (date == '2/1/2024') {
    text = 'океан';
  } else if (date == '3/1/2024') {
    text = 'кобац';
  } else if (date == '4/1/2024') {
    text = 'машта';
  } else if (date == '5/1/2024') {
    text = 'панда';
  } else if (date == '6/1/2024') {
    text = 'иступ';
  } else if (date == '7/1/2024') {
    text = 'пулпа';
  } else if (date == '8/1/2024') {
    text = 'пожар';
  } else if (date == '9/1/2024') {
    text = 'потез';
  } else if (date == '10/1/2024') {
    text = 'јунак';
  } else if (date == '11/1/2024') {
    text = 'ликер';
  } else if (date == '12/1/2024') {
    text = 'трава';
  } else if (date == '13/1/2024') {
    text = 'птица';
  } else if (date == '14/1/2024') {
    text = 'слово';
  } else if (date == '15/1/2024') {
    text = 'марка';
  } else if (date == '16/1/2024') {
    text = 'рашље';
  } else if (date == '17/1/2024') {
    text = 'тумач';
  } else if (date == '18/1/2024') {
    text = 'језик';
  } else if (date == '19/1/2024') {
    text = 'корал';
  } else if (date == '20/1/2024') {
    text = 'отвор';
  } else if (date == '21/1/2024') {
    text = 'клека';
  } else if (date == '22/1/2024') {
    text = 'сурла';
  } else if (date == '23/1/2024') {
    text = 'закон';
  } else if (date == '24/1/2024') {
    text = 'чекић';
  } else if (date == '25/1/2024') {
    text = 'нехат';
  } else if (date == '26/1/2024') {
    text = 'идеал';
  } else if (date == '27/1/2024') {
    text = 'олтар';
  } else if (date == '28/1/2024') {
    text = 'писта';
  } else if (date == '29/1/2024') {
    text = 'зрење';
  } else if (date == '30/1/2024') {
    text = 'корен';
  } else if (date == '31/1/2024') {
    text = 'расол';
  } else if (date == '1/2/2024') {
    text = 'намаз';
  } else if (date == '2/2/2024') {
    text = 'задах';
  } else if (date == '3/2/2024') {
    text = 'рукав';
  } else if (date == '4/2/2024') {
    text = 'рвање';
  } else if (date == '5/2/2024') {
    text = 'легат';
  } else if (date == '6/2/2024') {
    text = 'лутка';
  } else if (date == '7/2/2024') {
    text = 'јецај';
  } else if (date == '8/2/2024') {
    text = 'стена';
  } else if (date == '9/2/2024') {
    text = 'трска';
  } else if (date == '10/2/2024') {
    text = 'спреј';
  } else if (date == '11/2/2024') {
    text = 'торањ';
  } else if (date == '12/2/2024') {
    text = 'кљусе';
  } else if (date == '13/2/2024') {
    text = 'обзир';
  } else if (date == '14/2/2024') {
    text = 'сплет';
  } else if (date == '15/2/2024') {
    text = 'кичма';
  } else if (date == '16/2/2024') {
    text = 'левак';
  } else if (date == '17/2/2024') {
    text = 'помпа';
  } else if (date == '18/2/2024') {
    text = 'хвала';
  } else if (date == '19/2/2024') {
    text = 'жабар';
  } else if (date == '20/2/2024') {
    text = 'крава';
  } else if (date == '21/2/2024') {
    text = 'кљова';
  } else if (date == '22/2/2024') {
    text = 'пасуљ';
  } else if (date == '23/2/2024') {
    text = 'спрат';
  } else if (date == '24/2/2024') {
    text = 'ташта';
  } else if (date == '25/2/2024') {
    text = 'сумња';
  } else if (date == '26/2/2024') {
    text = 'узгој';
  } else if (date == '27/2/2024') {
    text = 'сцена';
  } else if (date == '28/2/2024') {
    text = 'амбис';
  } else if (date == '29/2/2024') {
    text = 'лапот';
  } else if (date == '1/3/2024') {
    text = 'силос';
  } else if (date == '2/3/2024') {
    text = 'помен';
  } else if (date == '3/3/2024') {
    text = 'ларва';
  } else if (date == '4/3/2024') {
    text = 'срећа';
  } else if (date == '5/3/2024') {
    text = 'пруга';
  } else if (date == '6/3/2024') {
    text = 'напад';
  } else if (date == '7/3/2024') {
    text = 'школа';
  } else if (date == '8/3/2024') {
    text = 'шерпа';
  } else if (date == '9/3/2024') {
    text = 'летач';
  } else if (date == '10/3/2024') {
    text = 'јалов';
  } else if (date == '11/3/2024') {
    text = 'поход';
  } else if (date == '12/3/2024') {
    text = 'шофер';
  } else if (date == '13/3/2024') {
    text = 'ензим';
  } else if (date == '14/3/2024') {
    text = 'степа';
  } else if (date == '15/3/2024') {
    text = 'оклоп';
  } else if (date == '16/3/2024') {
    text = 'измет';
  } else if (date == '17/3/2024') {
    text = 'финиш';
  } else if (date == '18/3/2024') {
    text = 'мачка';
  } else if (date == '19/3/2024') {
    text = 'роман';
  } else if (date == '20/3/2024') {
    text = 'табак';
  } else if (date == '21/3/2024') {
    text = 'топуз';
  } else if (date == '22/3/2024') {
    text = 'ивица';
  } else if (date == '23/3/2024') {
    text = 'мерач';
  } else if (date == '24/3/2024') {
    text = 'клопа';
  } else if (date == '25/3/2024') {
    text = 'тужба';
  } else if (date == '26/3/2024') {
    text = 'проба';
  } else if (date == '27/3/2024') {
    text = 'запис';
  } else if (date == '28/3/2024') {
    text = 'ћошак';
  } else if (date == '29/3/2024') {
    text = 'мућка';
  } else if (date == '30/3/2024') {
    text = 'салон';
  } else if (date == '31/3/2024') {
    text = 'љуска';
  } else if (date == '1/4/2024') {
    text = 'јутро';
  } else if (date == '2/4/2024') {
    text = 'смена';
  } else if (date == '3/4/2024') {
    text = 'откуп';
  } else if (date == '4/4/2024') {
    text = 'регал';
  } else if (date == '5/4/2024') {
    text = 'имање';
  } else if (date == '6/4/2024') {
    text = 'ћерка';
  } else if (date == '7/4/2024') {
    text = 'игало';
  } else if (date == '8/4/2024') {
    text = 'самрт';
  } else if (date == '9/4/2024') {
    text = 'клица';
  } else if (date == '10/4/2024') {
    text = 'етика';
  } else if (date == '11/4/2024') {
    text = 'зрнце';
  } else if (date == '12/4/2024') {
    text = 'пасаж';
  } else if (date == '13/4/2024') {
    text = 'посао';
  } else if (date == '14/4/2024') {
    text = 'марва';
  } else if (date == '15/4/2024') {
    text = 'јелен';
  } else if (date == '16/4/2024') {
    text = 'пуцањ';
  } else if (date == '17/4/2024') {
    text = 'тигањ';
  } else if (date == '18/4/2024') {
    text = 'коцка';
  } else if (date == '19/4/2024') {
    text = 'таван';
  } else if (date == '20/4/2024') {
    text = 'минус';
  } else if (date == '21/4/2024') {
    text = 'химна';
  } else if (date == '22/4/2024') {
    text = 'ослић';
  } else if (date == '23/4/2024') {
    text = 'осека';
  } else if (date == '24/4/2024') {
    text = 'фарма';
  } else if (date == '25/4/2024') {
    text = 'трење';
  } else if (date == '26/4/2024') {
    text = 'скаут';
  } else if (date == '27/4/2024') {
    text = 'рерна';
  } else if (date == '28/4/2024') {
    text = 'оксид';
  } else if (date == '29/4/2024') {
    text = 'осуда';
  } else if (date == '30/4/2024') {
    text = 'морал';
  } else if (date == '1/5/2024') {
    text = 'лисац';
  } else if (date == '2/5/2024') {
    text = 'мрљав';
  } else if (date == '3/5/2024') {
    text = 'манир';
  } else if (date == '4/5/2024') {
    text = 'реума';
  } else if (date == '5/5/2024') {
    text = 'нагон';
  } else if (date == '6/5/2024') {
    text = 'чудак';
  } else if (date == '7/5/2024') {
    text = 'ждрал';
  } else if (date == '8/5/2024') {
    text = 'место';
  } else if (date == '9/5/2024') {
    text = 'орден';
  } else if (date == '10/5/2024') {
    text = 'племе';
  } else if (date == '11/5/2024') {
    text = 'лонац';
  } else if (date == '12/5/2024') {
    text = 'жабац';
  } else if (date == '13/5/2024') {
    text = 'права';
  } else if (date == '14/5/2024') {
    text = 'одраз';
  } else if (date == '15/5/2024') {
    text = 'ћуран';
  } else if (date == '16/5/2024') {
    text = 'шифра';
  } else if (date == '17/5/2024') {
    text = 'калуп';
  } else if (date == '18/5/2024') {
    text = 'канџа';
  } else if (date == '19/5/2024') {
    text = 'румба';
  } else if (date == '20/5/2024') {
    text = 'расад';
  } else if (date == '21/5/2024') {
    text = 'лимит';
  } else if (date == '22/5/2024') {
    text = 'човек';
  } else if (date == '23/5/2024') {
    text = 'првак';
  } else if (date == '24/5/2024') {
    text = 'слуга';
  } else if (date == '25/5/2024') {
    text = 'палац';
  } else if (date == '26/5/2024') {
    text = 'посед';
  } else if (date == '27/5/2024') {
    text = 'немир';
  } else if (date == '28/5/2024') {
    text = 'сатен';
  } else if (date == '29/5/2024') {
    text = 'проја';
  } else if (date == '30/5/2024') {
    text = 'трзај';
  } else if (date == '31/5/2024') {
    text = 'помоћ';
  } else if (date == '1/6/2024') {
    text = 'цимет';
  } else if (date == '2/6/2024') {
    text = 'терет';
  } else if (date == '3/6/2024') {
    text = 'купач';
  } else if (date == '4/6/2024') {
    text = 'клише';
  } else if (date == '5/6/2024') {
    text = 'понор';
  } else if (date == '6/6/2024') {
    text = 'исказ';
  } else if (date == '7/6/2024') {
    text = 'ласта';
  } else if (date == '8/6/2024') {
    text = 'малер';
  } else if (date == '9/6/2024') {
    text = 'журка';
  } else if (date == '10/6/2024') {
    text = 'слина';
  } else if (date == '11/6/2024') {
    text = 'талац';
  } else if (date == '12/6/2024') {
    text = 'пакла';
  } else if (date == '13/6/2024') {
    text = 'ритам';
  } else if (date == '14/6/2024') {
    text = 'свила';
  } else if (date == '15/6/2024') {
    text = 'обала';
  } else if (date == '16/6/2024') {
    text = 'конус';
  } else if (date == '17/6/2024') {
    text = 'назеб';
  } else if (date == '18/6/2024') {
    text = 'радња';
  } else if (date == '19/6/2024') {
    text = 'снаја';
  } else if (date == '20/6/2024') {
    text = 'нагао';
  } else if (date == '21/6/2024') {
    text = 'плажа';
  } else if (date == '22/6/2024') {
    text = 'точак';
  } else if (date == '23/6/2024') {
    text = 'пошта';
  } else if (date == '24/6/2024') {
    text = 'масер';
  } else if (date == '25/6/2024') {
    text = 'север';
  } else if (date == '26/6/2024') {
    text = 'мамут';
  } else if (date == '27/6/2024') {
    text = 'нотес';
  } else if (date == '28/6/2024') {
    text = 'чокот';
  } else if (date == '29/6/2024') {
    text = 'клима';
  } else if (date == '30/6/2024') {
    text = 'лимфа';
  } else if (date == '1/7/2024') {
    text = 'залив';
  } else if (date == '2/7/2024') {
    text = 'јесен';
  } else if (date == '3/7/2024') {
    text = 'џокеј';
  } else if (date == '4/7/2024') {
    text = 'тепих';
  } else if (date == '5/7/2024') {
    text = 'понос';
  } else if (date == '6/7/2024') {
    text = 'месец';
  } else if (date == '7/7/2024') {
    text = 'мечка';
  } else if (date == '8/7/2024') {
    text = 'спруд';
  } else if (date == '9/7/2024') {
    text = 'класа';
  } else if (date == '10/7/2024') {
    text = 'парче';
  } else if (date == '11/7/2024') {
    text = 'секта';
  } else if (date == '12/7/2024') {
    text = 'умњак';
  } else if (date == '13/7/2024') {
    text = 'купон';
  } else if (date == '14/7/2024') {
    text = 'појас';
  } else if (date == '15/7/2024') {
    text = 'ротор';
  } else if (date == '16/7/2024') {
    text = 'питон';
  } else if (date == '17/7/2024') {
    text = 'жудња';
  } else if (date == '18/7/2024') {
    text = 'отмен';
  } else if (date == '19/7/2024') {
    text = 'сапун';
  } else if (date == '20/7/2024') {
    text = 'секач';
  } else if (date == '21/7/2024') {
    text = 'патос';
  } else if (date == '22/7/2024') {
    text = 'сачма';
  } else if (date == '23/7/2024') {
    text = 'шворц';
  } else if (date == '24/7/2024') {
    text = 'колач';
  } else if (date == '25/7/2024') {
    text = 'летва';
  } else if (date == '26/7/2024') {
    text = 'испад';
  } else if (date == '27/7/2024') {
    text = 'улога';
  } else if (date == '28/7/2024') {
    text = 'магма';
  } else if (date == '29/7/2024') {
    text = 'резач';
  } else if (date == '30/7/2024') {
    text = 'лопов';
  } else if (date == '31/7/2024') {
    text = 'одјек';
  } else if (date == '1/8/2024') {
    text = 'хорор';
  } else if (date == '2/8/2024') {
    text = 'прасе';
  } else if (date == '3/8/2024') {
    text = 'јавор';
  } else if (date == '4/8/2024') {
    text = 'жилет';
  } else if (date == '5/8/2024') {
    text = 'кукољ';
  } else if (date == '6/8/2024') {
    text = 'чиода';
  } else if (date == '7/8/2024') {
    text = 'особа';
  } else if (date == '8/8/2024') {
    text = 'зачас';
  } else if (date == '9/8/2024') {
    text = 'зазор';
  } else if (date == '10/8/2024') {
    text = 'плебс';
  } else if (date == '11/8/2024') {
    text = 'фауна';
  } else if (date == '12/8/2024') {
    text = 'пажња';
  } else if (date == '13/8/2024') {
    text = 'метан';
  } else if (date == '14/8/2024') {
    text = 'сањар';
  } else if (date == '15/8/2024') {
    text = 'чорба';
  } else if (date == '16/8/2024') {
    text = 'косач';
  } else if (date == '17/8/2024') {
    text = 'зебра';
  } else if (date == '18/8/2024') {
    text = 'надев';
  } else if (date == '19/8/2024') {
    text = 'крмак';
  } else if (date == '20/8/2024') {
    text = 'чувар';
  } else if (date == '21/8/2024') {
    text = 'звоно';
  } else if (date == '22/8/2024') {
    text = 'паста';
  } else if (date == '23/8/2024') {
    text = 'крмељ';
  } else if (date == '24/8/2024') {
    text = 'чучањ';
  } else if (date == '25/8/2024') {
    text = 'пират';
  } else if (date == '26/8/2024') {
    text = 'чабар';
  } else if (date == '27/8/2024') {
    text = 'изрез';
  } else if (date == '28/8/2024') {
    text = 'ловор';
  } else if (date == '29/8/2024') {
    text = 'пегла';
  } else if (date == '30/8/2024') {
    text = 'пораз';
  } else if (date == '31/8/2024') {
    text = 'извод';
  } else if (date == '1/9/2024') {
    text = 'чемер';
  } else if (date == '2/9/2024') {
    text = 'маска';
  } else if (date == '3/9/2024') {
    text = 'мотел';
  } else if (date == '4/9/2024') {
    text = 'петао';
  } else if (date == '5/9/2024') {
    text = 'покоп';
  } else if (date == '6/9/2024') {
    text = 'занат';
  } else if (date == '7/9/2024') {
    text = 'фитиљ';
  } else if (date == '8/9/2024') {
    text = 'путар';
  } else if (date == '9/9/2024') {
    text = 'штиво';
  } else if (date == '10/9/2024') {
    text = 'стриц';
  } else if (date == '11/9/2024') {
    text = 'курир';
  } else if (date == '12/9/2024') {
    text = 'оруђе';
  } else if (date == '13/9/2024') {
    text = 'табор';
  } else if (date == '14/9/2024') {
    text = 'покор';
  } else if (date == '15/9/2024') {
    text = 'мачор';
  } else {
    text = 'ништа';
  }

  return btoa(unescape(encodeURIComponent((text.toUpperCase()))))
}

const getTodaysDate = () => {
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  return date;
}





function App() {
  const initialStates = {
    answer: () => getRandomAnswer(),
    gameState: state.playing,
    gameShareState: state.notclicked,
    board: [
      ['', '', '', '', ''],
      ['', '', '', '', ''],
      ['', '', '', '', ''],
      ['', '', '', '', ''],
      ['', '', '', '', ''],
      ['', '', '', '', ''],
    ],
    cellStatuses: Array(6).fill(Array(5).fill(status.unguessed)),
    currentRow: 0,
    currentCol: 0,
    letterStatuses: () => {
      const letterStatuses = {}
      letters.forEach((letter) => {
        letterStatuses[letter] = status.unguessed
      })
      return letterStatuses
    },
    submittedInvalidWord: false,
    dateToday: () => getTodaysDate(),
  }

  const [answer, setAnswer] = useLocalStorage('stateAnswer', initialStates.answer())
  const [gameState, setGameState] = useLocalStorage('stateGameState', initialStates.gameState)
  const [board, setBoard] = useLocalStorage('stateBoard', initialStates.board)
  const [cellStatuses, setCellStatuses] = useLocalStorage('stateCellStatuses', initialStates.cellStatuses)
  const [currentRow, setCurrentRow] = useLocalStorage('stateCurrentRow', initialStates.currentRow)
  const [currentCol, setCurrentCol] = useLocalStorage('stateCurrentCol', initialStates.currentCol)
  const [letterStatuses, setLetterStatuses] = useLocalStorage('stateLetterStatuses', initialStates.letterStatuses())
  const [submittedInvalidWord, setSubmittedInvalidWord] = useLocalStorage('stateSubmittedInvalidWord', initialStates.submittedInvalidWord)
  const [dateToday, setDateToday] = useLocalStorage('stateDate', initialStates.dateToday)

  const [currentStreak, setCurrentStreak] = useLocalStorage('current-streak', 0)
  const [longestStreak, setLongestStreak] = useLocalStorage('longest-streak', 0)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [firstTime, setFirstTime] = useLocalStorage('first-time', true)
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(firstTime)
  const [settingsModalIsOpen, setSettingsModalIsOpen] = useState(false)

  const [playConfetti, setPlayConfetti] = useState(false)

  const openModal = () => setIsOpen(true)

  const handleInfoClose = () => {
    setFirstTime(false)
    setInfoModalIsOpen(false)
  }

  const handleEndGameClose = () => {
    setIsOpen(false)
    playAgain()
  }



  const check = () => {
    if (getTodaysDate() != dateToday) {
      playAgain();
    }

  }

  useEffect(() => {
    check()
  }, [initialStates])

  const [darkMode, setDarkMode] = useLocalStorage('dark-mode', false)
  const toggleDarkMode = () => setDarkMode((prev) => !prev)

  useEffect(() => {
    if (gameState !== state.playing) {
      setTimeout(() => {
        setPlayConfetti(false)
        openModal()
      }, 4500)

      setTimeout(() => {
        setPlayConfetti(true)
      }, 2500)

    }
  }, [gameState])



  const getCellStyles = (rowNumber, colNumber, letter) => {
    if (rowNumber === currentRow) {
      if (letter) {
        return `animate__animated animate__pulse animate__faster nm-inset-background dark:nm-inset-background-dark text-primary dark:text-primary-dark ${submittedInvalidWord ? 'border border-red-800' : ''
          }`
      }
      return 'nm-flat-background dark:nm-flat-background-dark text-primary dark:text-primary-dark'
    }

    var delay = ''
    if (rowNumber == currentRow - 1) {
      delay = 'delay-col-' + colNumber + ' '
    }

    switch (cellStatuses[rowNumber][colNumber]) {
      case status.green:
        return delay + 'nm-inset-n-green text-gray-50 animate__animated animate__flipInX'
      case status.yellow:
        return delay + 'nm-inset-yellow-500 text-gray-50 animate__animated animate__flipInX'
      case status.gray:
        return delay + 'nm-inset-n-gray text-gray-50 animate__animated animate__flipInX'
      default:
        return 'nm-flat-background dark:nm-flat-background-dark text-primary dark:text-primary-dark'
    }
  }


  const getDelay = (colNumber) => {
    return 'animationDelay:' + colNumber + 's '
  }



  const getCellStylesForSharing = (rowNumber, colNumber) => {
    switch (cellStatuses[rowNumber][colNumber]) {
      case status.green:
        return '🟢'
      case status.yellow:
        return '🟡'
      case status.gray:
        return '⚫'
      default:
        return 'nm-flat-background dark:nm-flat-background-dark text-primary dark:text-primary-dark'
    }
  }





  const addLetter = (letter) => {
    setSubmittedInvalidWord(false)
    setBoard((prev) => {
      if (currentCol > 4) {
        return prev
      }
      const newBoard = [...prev]
      newBoard[currentRow][currentCol] = letter
      return newBoard
    })
    if (currentCol < 5) {
      setCurrentCol((prev) => prev + 1)
    }
  }

  const addLetterEng = (letter) => {
    setSubmittedInvalidWord(false)
    setBoard((prev) => {
      if (currentCol > 4) {
        return prev
      }
      const newBoard = [...prev]
      newBoard[currentRow][currentCol] = letterEngToSrb(letter)
      return newBoard
    })
    if (currentCol < 5) {
      setCurrentCol((prev) => prev + 1)
    }
  }


  const letterEngToSrb = (letter) => {
    return letters_eng_srb[1][letters_eng_srb[0].indexOf(letter)];
  }


  const isValidWord = (word) => {
    if (word.length < 5) return false
    return words[word.toLowerCase()]
  }


  const onEnterPress = () => {
    const word = board[currentRow].join('')
    if (!isValidWord(word)) {
      setSubmittedInvalidWord(true)
      return
    }

    if (currentRow === 6) return

    updateCellStatuses(word, currentRow)
    updateLetterStatuses(word)
    setCurrentRow((prev) => prev + 1)
    setCurrentCol(0)
  }

  const onDeletePress = () => {
    setSubmittedInvalidWord(false)
    if (currentCol === 0) return

    setBoard((prev) => {
      const newBoard = [...prev]
      newBoard[currentRow][currentCol - 1] = ''
      return newBoard
    })

    setCurrentCol((prev) => prev - 1)
  }

  const updateCellStatuses = (word, rowNumber) => {
    setCellStatuses((prev) => {
      const newCellStatuses = [...prev]
      newCellStatuses[rowNumber] = [...prev[rowNumber]]
      const wordLength = word.length
      const answerLetters = decodeURIComponent(escape(window.atob((answer)))).split('')

      // set all to gray
      for (let i = 0; i < wordLength; i++) {
        newCellStatuses[rowNumber][i] = status.gray
      }

      // check greens
      for (let i = wordLength - 1; i >= 0; i--) {
        if (word[i] === decodeURIComponent(escape(window.atob(answer)))[i]) {
          newCellStatuses[rowNumber][i] = status.green
          answerLetters.splice(i, 1)
        }
      }

      // check yellows
      for (let i = 0; i < wordLength; i++) {
        if (answerLetters.includes(word[i]) && newCellStatuses[rowNumber][i] !== status.green) {
          newCellStatuses[rowNumber][i] = status.yellow
          answerLetters.splice(answerLetters.indexOf(word[i]), 1)
        }
      }

      return newCellStatuses
    })
  }

  const isRowAllGreen = (row) => {
    return row.every((cell) => cell === status.green)
  }

  // every time cellStatuses updates, check if the game is won or lost
  useEffect(() => {
    const cellStatusesCopy = [...cellStatuses]
    const reversedStatuses = cellStatusesCopy.reverse()
    const lastFilledRow = reversedStatuses.find((r) => {
      return r[0] !== status.unguessed
    })

    if (gameState === state.playing && lastFilledRow && isRowAllGreen(lastFilledRow)) {
      setGameState(state.won)

      //shareResult()

      var streak = currentStreak + 1
      setCurrentStreak(streak)
      setLongestStreak((prev) => streak > prev ? streak : prev)
    } else if (gameState === state.playing && currentRow === 6) {
      setGameState(state.lost)
      setCurrentStreak(0)
    }
  }, [
    cellStatuses, currentRow, gameState, setGameState,
    currentStreak, setCurrentStreak, setLongestStreak
  ])

  const updateLetterStatuses = (word) => {
    setLetterStatuses((prev) => {
      const newLetterStatuses = { ...prev }
      const wordLength = word.length
      for (let i = 0; i < wordLength; i++) {
        if (newLetterStatuses[word[i]] === status.green) continue

        if (word[i] === decodeURIComponent(escape(window.atob(answer)))[i]) {
          newLetterStatuses[word[i]] = status.green
        } else if (decodeURIComponent(escape(window.atob(answer))).includes(word[i])) {
          newLetterStatuses[word[i]] = status.yellow
        } else {
          newLetterStatuses[word[i]] = status.gray
        }
      }
      return newLetterStatuses
    })
  }


  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }


  const playAgain = () => {

    // redirect to mobile if mobile and Android
    if (isMobile) {
      //console.log('mobile');
      //console.log(firstTime);
      if (firstTime) {
        let os = getMobileOperatingSystem();
        if (os == "Android") {
          window.location.replace("https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=pm.f2p.vordli");
        } else if (os == "iOS") {
          window.location.replace("https://apps.apple.com/us/app/vordli/id1613087917");
        } else if (os == "Windows Phone") {
          //window.location.href = "http://www.WindowsPhoneexample.com";
        } else {
          //window.location.href = "http://www.NowherLandexample.com";
        }
      }

    } else {
      //console.log('desktop');

    };
    //


    setDateToday(initialStates.dateToday)
    setAnswer(initialStates.answer())
    setGameState(initialStates.gameState)
    setBoard(initialStates.board)
    setCellStatuses(initialStates.cellStatuses)
    setCurrentRow(initialStates.currentRow)
    setCurrentCol(initialStates.currentCol)
    setLetterStatuses(initialStates.letterStatuses())
    setSubmittedInvalidWord(initialStates.submittedInvalidWord)


    //handleEndGameClose()
  }


  const shareResult = () => {
    const date = dateToday;

    var text = '';
    for (let i = 0; i < currentRow; i++) {
      for (let j = 0; j < 5; j++) {
        text += getCellStylesForSharing(i, j)
      }
      text += '\n';
    }


    const shareData = {
      title: 'Вордли',
      text: '\'Вордли - Погоди реч\' за ' + date + ': ' + currentRow + '/6\n' + text + '',
      url: ''
    }

    try {
      navigator.share(shareData)
      //sucess
    } catch (err) {
      var textField = document.createElement('textarea')
      textField.value = '\'Вордли - Погоди реч\' за ' + date + ': ' + currentRow + '/6\n' + text + 'vordli.com'


      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
    }




  }


  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: darkMode ? 'hsl(231, 16%, 25%)' : 'hsl(231, 16%, 92%)',
      zIndex: 99,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      height: 'calc(100% - 2rem)',
      width: 'calc(100% - 2rem)',
      backgroundColor: darkMode ? 'hsl(231, 16%, 25%)' : 'hsl(231, 16%, 92%)',
      boxShadow: `${darkMode
          ? '0.2em 0.2em calc(0.2em * 2) #252834, calc(0.2em * -1) calc(0.2em * -1) calc(0.2em * 2) #43475C'
          : '0.2em 0.2em calc(0.2em * 2) #A3A7BD, calc(0.2em * -1) calc(0.2em * -1) calc(0.2em * 2) #FFFFFF'
        }`,
      border: 'none',
      borderRadius: '1rem',
      maxWidth: '475px',
      maxHeight: '750px',
      position: 'relative',
    },
  }

  return (
    <div className={darkMode ? 'dark' : ''}>
      <div className={`flex flex-col justify-between h-fill bg-background dark:bg-background-dark`}>
        <header className="flex items-center py-2 px-3 text-primary dark:text-primary-dark">
          <button
            type="button"
            onClick={() => setSettingsModalIsOpen(true)}
            className="p-1 rounded-full"
          >
            <Settings />
          </button>



          <h1 className="flex-1 text-center text-xl xxs:text-2xl sm:text-4xl tracking-wide font-bold font-righteous animate__animated animate__slideInDown">
            <img src={logo} alt="logo" className="inline-flex items-center justify-center w-10 h-10 " /> ВОРДЛИ
          </h1>
          <button
            type="button"
            onClick={() => setInfoModalIsOpen(true)}
            className="p-1 rounded-full"
          >
            <Info />
          </button>
        </header>
        <div className="flex items-center flex-col py-3 flex-1 justify-center relative">
          <div className="relative">
            <div className="grid grid-cols-5 grid-flow-row gap-4">
              {board.map((row, rowNumber) =>
                row.map((letter, colNumber) => (
                  <span
                    key={colNumber}


                    className={`${getCellStyles(
                      rowNumber,
                      colNumber,
                      letter
                    )} inline-flex items-center font-medium justify-center text-lg w-[13vw] h-[13vw] xs:w-14 xs:h-14 sm:w-20 sm:h-20 rounded-full`}



                  >
                    {letter}
                  </span>
                ))
              )}
            </div>
            <div
              className={`absolute -bottom-24 left-1/2 transform -translate-x-1/2 ${gameState === state.playing ? 'hidden' : ''
                }`}
            >

              {/*



              <div className={darkMode ? 'dark' : ''}>
                <button
                  type="button"
                  className="rounded-lg z-10 px-6 py-2 text-lg nm-flat-background dark:nm-flat-background-dark hover:nm-inset-background dark:hover:nm-inset-background-dark text-primary dark:text-primary-dark"
                  onClick={playAgain}
                >
                  Покушај поново
                </button>
              </div>

              */}


            </div>
          </div>
          <Confetti active={playConfetti} config={confettiConfig} />
        </div>
        <InfoModal
          isOpen={infoModalIsOpen}
          handleClose={handleInfoClose}
          darkMode={darkMode}
          styles={modalStyles}
        />
        <EndGameModal
          isOpen={modalIsOpen}
          handleClose={handleEndGameClose}
          styles={modalStyles}
          darkMode={darkMode}
          gameState={gameState}
          state={state}
          currentStreak={currentStreak}
          longestStreak={longestStreak}
          answer={answer}
          playAgain={playAgain}
          shareResult={shareResult}
        />
        <SettingsModal
          isOpen={settingsModalIsOpen}
          handleClose={() =>
            setSettingsModalIsOpen(false)
          }
          styles={modalStyles}
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
        />
        <div className={`h-auto pb-10 relative ${gameState === state.playing ? '' : 'delay-2600 animate__animated animate__zoomOutDown'}`}>
          <Keyboard
            letterStatuses={letterStatuses}
            addLetter={addLetter}
            onEnterPress={onEnterPress}
            onDeletePress={onDeletePress}
            gameDisabled={gameState !== state.playing}
            addLetterEng={addLetterEng}
          />
        </div>


      </div>

    </div>

  )
}

export default App
