export const keyboardLetters = [
  ['Љ', 'Њ', 'Е', 'Р', 'Т', 'З', 'У', 'И', 'О', 'П', 'Ш', 'Ђ'],
  ['А', 'С', 'Д', 'Ф', 'Г', 'Х', 'Ј', 'К', 'Л', 'Ч', 'Ћ', 'Ж'],
  ['Џ', 'Ц', 'В', 'Б', 'Н', 'М']
]

export const letters = [
  'А',
  'Б',
  'В',
  'Г',
  'Д',
  'Ђ',
  'Е',
  'Ж',
  'З',
  'И',
  'Ј',
  'К',
  'Л',
  'Љ',
  'М',
  'Н',
  'Њ',
  'О',
  'П',
  'Р',
  'С',
  'Т',
  'Ћ',
  'У',
  'Ф',
  'Х',
  'Ц',
  'Ч',
  'Џ',
  'Ш',
]

export const letters_eng_srb = [
  ['A',
  'B',
  'V',
  'G',
  'D',
  '}',
  ']',
  'E',
  '|',
  '\\',
  'Z',
  'Y',
  'I',
  'J',
  'K',
  'L',
  'Q',
  'M',
  'N',
  'W',
  'O',
  'P',
  'R',
  'S',
  'T',
  '"',
  '\'',
  'U',
  'F',
  'H',
  'C',
  ':',
  ';',
  'X',
  '{',
  '['],
  [
    'А',
    'Б',
    'В',
    'Г',
    'Д',
    'Ђ',
    'Ђ',
    'Е',
    'Ж',
    'Ж',
    'З',
    'З',
    'И',
    'Ј',
    'К',
    'Л',
    'Љ',
    'М',
    'Н',
    'Њ',
    'О',
    'П',
    'Р',
    'С',
    'Т',
    'Ћ',
    'Ћ',
    'У',
    'Ф',
    'Х',
    'Ц',
    'Ч',
    'Ч',
    'Џ',
    'Ш',
    'Ш'
  ]

]




export const status = {
  green: 'green',
  yellow: 'yellow',
  gray: 'gray',
  unguessed: 'unguessed',
}
